.loader-wrapper {
  height: 100vh;
  display: flex;
  align-items: center;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.app {
  margin-top: 3em;
  display: flex;
  justify-content: center;
}
/* .app {
  display: flex;
  justify-content: center;
} */

.app-container {
  position: relative;
  width: 1250px;
  height: 585px;
}

@media screen and (max-width: 1100px) {
  html,
  body,
  #root {
    height: 100%;
  }
  .app {
    margin-top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .app-container {
    position: relative;
    width: 100%;
    height: 100%;
  }
}
